/* eslint-disable */
const TARGET_ID = 'collection-wrapper'

const passfortScriptElement = document.currentScript;

class PassFortCollect {
    static _getCurrentOrigin() {
        const thisScript = new URL(passfortScriptElement.src);

        return thisScript.origin;
    }

    // Spawn the iframe into the predefined container
    static _createIframe(request_id, apikey) {
        const iframe_source = this._getCurrentOrigin()

        var iframe = document.createElement('iframe')
        iframe.sandbox = 'allow-same-origin allow-top-navigation allow-scripts allow-forms allow-popups'
        // Allow camera and microphone access in Chrome versions >64
        iframe.allow = 'camera;microphone'
        iframe.id = 'collection'
        iframe.src = iframe_source

        iframe.style.position = 'absolute'
        iframe.style.top = '0px'
        iframe.style.bottom = '0px'
        iframe.style.right = '0px'
        iframe.style.border = 'none'
        iframe.style.margin = '0'
        iframe.style.padding = '0'
        iframe.style.overflow = 'auto'
        iframe.style.height = '100%'
        iframe.style.width = '100%'

        document.getElementById(TARGET_ID).appendChild(iframe)

        window.onmessage = msg => {
            if (msg.data === 'getRequestParams') {
                const msg = {request_id: request_id, apikey: apikey}
                document.getElementById('collection').contentWindow.postMessage(msg, '*')
            } else if (msg.data === 'reloadCollection') {
                document.getElementById('collection').src += ''
            }
        }
    }

    // Remove the apikey parameter from the URL history
    static _redactURL(apikey) {
        const regex = /&?apikey=[0-9a-f]+&?/
        if (window.history.replaceState) {
            const redacted = window.location.href.replace(regex, '')
            window.history.replaceState({}, null, redacted)
        }
    }

    // Initialise from an ordinary function call
    static init(params) {
        this._createIframe(params.request_id, params.apikey)
    }

    // Pull init arguments from the outer window URL
    static initFromUrl() {
        var getParams = function(url) {
            var params = {}
            var parser = document.createElement('a')
            parser.href = url
            var query = parser.search.substring(1)
            var vars = query.split('&')
            for (var i = 0; i < vars.length; i++) {
                var pair = vars[i].split('=')
                params[pair[0]] = decodeURIComponent(pair[1])
            }
            return params
        }

        const params = getParams(window.location.href)
        const apikey = params['apikey']
        const request_id = params['request_id']

        if(!apikey || !request_id) {
            const msg = "Missing apikey or request_id in query parameters"
            console.error(msg)
            throw new Error(msg)
        }

        this._createIframe(request_id, apikey)
        this._redactURL(apikey)
    }
}

window.PassFortCollect = PassFortCollect
